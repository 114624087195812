<template>
	<section>
		<section class="userInf">
			<section class="userInf__upperSection">
				<h1 class="userInf__upperInformation text-format-1">
					Hola {{ userInfo?.name }}, parece que ya tenías un proceso pendiente de finalizar con
					los siguientes datos. ¿Quieres retomarlo? ¿O prefieres empezar uno nuevo?
				</h1>
			</section>
			<section class="cards">
				<div class="cards__info info">
					<span class="info__title text-format-2">Datos Personales</span>
					<div class="info__body">
						<div>
							<span>Nombre</span>
							<span>{{ userInfo?.name }}</span>
						</div>
						<div>
							<span>Fecha de nacimiento</span>
							<span>{{ this.formatDate(userInfo?.birthdate) }}</span>
						</div>
						<div>
							<span>Apellidos</span>
							<span>{{ userInfo?.secondSurname }}</span>
						</div>
						<div>
							<span>Población de nacimiento</span>
							<span class="cardInfo">{{ userInfo?.city }}</span>
						</div>
						<div>
							<span>Nº de documento</span>
							<span class="cardInfo">{{ this.ofuscate(userInfo?.documentId) }}</span>
						</div>
					</div>
				</div>

				<div
					class="cards__info info"
					v-if="userInfo.fiscalAddress?.streetType"
				>
					<span class="info__title text-format-2">Dirección</span>
					<div class="info__body">
						<div>
							<span>Tipo de vía</span>
							<span>{{ userInfo.fiscalAddress?.streetType.name }}</span>
						</div>
						<div>
							<span>Nombre de vía</span>
							<span>{{ userInfo.fiscalAddress?.streetName }}</span>
						</div>
						<div v-if="userInfo.fiscalAddress?.streetNumber">
							<span>Nº</span>
							<span>{{ userInfo.fiscalAddress?.streetNumber }}</span>
						</div>
						<div v-if="userInfo.fiscalAddress?.portal">
							<span>Portal</span>
							<span>{{ userInfo.fiscalAddress?.portal }}</span>
						</div>
						<div v-if="userInfo.fiscalAddress?.floor">
							<span>Piso</span>
							<span>{{ userInfo.fiscalAddress?.floor }}</span>
						</div>
						<div>
							<span>Población</span>
							<span>{{ userInfo.fiscalAddress?.place }}</span>
						</div>
					</div>
				</div>

				<div
					class="cards__info"
					v-if="userInfo.employmentSituation?.description"
				>
					<span class="info__title text-format-2">Datos Laborales</span>
					<div class="info__body">
						<div>
							<span>Situación laboral</span>
							<span>{{ userInfo.employmentSituation?.description }}</span>
						</div>
						<div>
							<span>Profesión</span>
							<span>{{ userInfo.employmentSituation?.profession?.name }}</span>
						</div>
						<div>
							<span>Nombre empresa</span>
							<span>{{ userInfo.employmentSituation?.company }}</span>
						</div>
						<div>
							<span>Origen fondos</span>
							<span>{{ userInfo.employmentSituation?.fundsOrigin }}</span>
						</div>
						<div class="info__body--largeRow">
							<span>Ingresos anuales</span>
							<span>{{ userInfo.employmentSituation?.annualIncome }}</span>
						</div>
					</div>
				</div>
			</section>
		</section>

		<section class="userActions">
			<div class="userActions__button">
				<button
					class="userActions__button--resume"
					@click="submitResume"
				>
					Retomar Proceso
				</button>
			</div>

			<div class="userActions__button">
				<button
					class="userActions__button--submitNew"
					@click="submitNew"
				>
					Empezar uno nuevo
				</button>
			</div>
		</section>
	</section>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'app-pending-onboarding',

	data: function () {
		return {
			userInfo: [],
		};
	},

	methods: {
		async getOnboardingData() {
			const res = await this.$store.dispatch(
				'onboarding/getOnBoarding',
				this.$store.state.onboarding.id
			);

			return res.data;
		},
		submitNew() {
			const email = this.userInfo.email;
			const phone = this.userInfo.phone;
			const name = this.userInfo.name;

			this.$store
				.dispatch('onboarding/createProcess', {
					email,
					phone,
					name,
					pendingSearch: false,
				})
				.then(() => {
					this.$router.push('app-personal-data');
				});
		},

		async getOfficesData() {
			const res = await this.$store.dispatch(
				'onboarding/getOffices',
				this.$store.state.onboarding.id
			);

			return res.data;
		},

		ofuscate(str) {
			return str[0] + '*******' + str[str.length - 2] + str[str.length - 1];
		},

		formatDate(date) {
			const dateObj = new Date(date);
			const month = dateObj.getMonth() + 1;
			const day = String(dateObj.getDate()).padStart(2, '0');
			const year = dateObj.getFullYear();

			return `${day}/${month}/${year}`;
		},

		async submitResume() {
			await this.$store.dispatch('onboarding/setClientInformation', this.userInfo);

			await this.$store.dispatch(
				'onboarding/sethasDoneIdentification',
				this.userInfo?.eidValidationRequested
			);

			switch (this.userInfo.step) {
				case 2:
					this.$router.push({ name: 'app-personal-data' });
					break;

				case 3:
					this.$router.push({ name: 'app-address' });
					break;

				case 4:
					this.$router.push({ name: 'app-professional-data' });
					break;

				case 5:
					this.$router.push({ name: 'app-gdpr-consent' });
					break;

				case 6:
					this.$router.push({ name: 'app-choose-options' });
					break;

				case 7:
					if (this.hasDoneIdentification) {
						this.$router.push('app-gdpr-consent');
					} else {
						await this.$store.dispatch('onboarding/decreaseStep', 1);
						this.$router.push({ name: 'app-choose-options' });
					}
					break;

				case 8:
					const { data: offices } = await this.getOfficesData();

					if (offices.length === 1) {
						this.$store
							.dispatch('onboarding/saveProcess', { officeId: offices[0].id })
							.then(() => this.$router.push('app-confirmation'));
					} else {
						this.$router.push({
							name: 'app-election-office',
							params: { offices },
						});
					}
					break;
			}
		},

		getDocumentType(str) {
			if (str) {
				const DNI_REGEX = /^(\d{8})([A-Z])$/;
				const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

				if (str.match(DNI_REGEX)) {
					return 'DNI';
				}

				if (str.match(NIE_REGEX)) {
					return 'NIE';
				}
			}
		},
	},

	computed: {
		...mapState('onboarding', ['hasDoneIdentification']),
	},

	async mounted() {
		const data = await this.getOnboardingData();

		this.userInfo = data.data;

		this.userInfo.documentType = this.getDocumentType(this.userInfo.documentId);
	},
};
</script>

<style lang="scss" scoped>
.userInf__upperInformation {
	width: 70%;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
}

.userInf__upperSection {
	display: flex;
	justify-content: center;
}

.cards {
	display: grid;
	flex-direction: row;
	margin-top: 30px;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	column-gap: 10px;
	row-gap: 20px;
}

.cards__info {
	display: flex;
	flex-direction: column;
}

.info__title {
	font-size: 18px;
	align-self: center;
	margin-bottom: 15px;
}

.info__body {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border: solid 1px #d5d6d7;
	border-radius: 5px;
	padding: 10px;
	column-gap: 5px;
	gap: 20px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #2d3f51;
	height: 100%;
}

.info__body > div {
	display: flex;
	flex-direction: column;
}

.info__body > div > span:first-child {
	font-size: 16px;
	color: #4b657d;
	font-weight: 500;
}

.info__body > div > span:last-child {
	font-size: 16px;
	color: #2d3f51;
	font-weight: 400;
}

.info__body--largeRow {
	grid-column: span 2;
}

.userActions__button {
	display: flex;
	justify-content: center;
	outline: none;
	user-select: none;
	appearance: none;
	white-space: nowrap;
}

.userActions__button--resume {
	color: $color-surface-light;
	width: 256px;
	border-radius: 20px;
	padding: 18px 32px 17px;
	margin-top: 30px;
	background-color: #2f5e84;
	font-size: small;
	font-weight: bold;
}

.userActions__button--submitNew {
	width: 256px;
	border-radius: 20px;
	padding: 18px 32px 17px;
	margin-top: 30px;
	font-size: small;
	font-weight: bold;
	border: solid 1px #2f5e84;
	background-color: #fff;
}

@media screen and (max-width: 767px) {
	.userInf__upperInformation {
		width: 80%;
	}
}
</style>
